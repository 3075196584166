import React from "react"
import { Box, Text, Image, Flex } from "@chakra-ui/react"
import { SOLUTIONS } from "../../../../constants"
import { COLORS } from "../../../../constants/theme"
import { FaPlus, FaMinus } from "react-icons/fa"
import { useAppContext } from "../../../../context"

const SolutionMobile = ({ value, onChange }) => {
  const { images } = useAppContext()
  return (
    <Box>
      {SOLUTIONS.map(item => (
        <>
          <Box
            flexDirection="row"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={5}
            onClick={e => onChange(item.title === value.title ? {} : item)}
            cursor="pointer"
            borderBottom={`1px solid ${
              item.title === value.title ? COLORS.secondary : COLORS.darkGrey
            }`}
            paddingBottom={2.5}
            px="2"
          >
            <Image
              src={
                item.title !== value.title
                  ? images[item.icon]
                  : images[item.activeIcon]
              }
              loading="eager"
              quality={95}
            />
            <Flex flexGrow={1} alignItems="center" px={2}>
              <Text
                fontSize="xl"
                fontWeight="bold"
                marginLeft="2.5"
                color={item.title === value.title ? "secondary" : "darkGrey"}
              >
                {item.title}
              </Text>
            </Flex>
            {item.title === value.title ? (
              <FaMinus color={COLORS.secondary} />
            ) : (
              <FaPlus color={COLORS.darkGrey} />
            )}
          </Box>
          {item.title === value.title && (
            <Box my={5}>
              <Text fontWeight="bold" fontSize="xl" color="secondary">
                THE PROBLEM
              </Text>
              <Text fontSize="md" fontWeight="medium" mt="2">
                {item.problem}
              </Text>
              <Text fontWeight="bold" fontSize="xl" color="secondary" mt="10">
                HOW WE SOLVE
              </Text>
              <Text fontSize="md" fontWeight="medium" mt="2">
                {item.solution}
              </Text>
            </Box>
          )}
        </>
      ))}
    </Box>
  )
}

export default SolutionMobile
