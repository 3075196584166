const createSolution = (title, icon, activeIcon, problem, solution) => ({
  title,
  icon,
  activeIcon,
  problem,
  solution,
})

const createTechnology = (title, ...rest) => ({
  title,
  images: rest,
})

const createJob = (title, location, link) => ({
  title,
  location,
  link,
})

const createExpertise = (image, title) => ({
  image,
  title,
})
const createClients = image => ({
  image,
})

export const SOLUTIONS = [
  createSolution(
    "Blockchain Consultations",
    "blockchain-consultation.svg",
    "blockchain-consultation-1.svg",
    "Since crypto apexed, the internet is clueless about the technological amenities it holds. Many users are just focused on the hype, unaware of the technical constraints and risks involved in the space, following the stream of creating ineffective products.",
    "Web3 is yet to be discovered and utilised in the future for meaningful purposes. We collaborate with our clients and guide them on possible solutions for making their initiative reach its maximum potential. We advise our clients at every critical phase and ensure a well-balanced roadmap for their Web3 endeavours."
  ),
  createSolution(
    "DApps",
    "DApp.svg",
    "DApp-1.svg",
    "The introduction of crypto ledger has opened a flood of opportunities, but it has also resulted in prolific and degenerate use cases crashing the cause of decentralisation.",
    "We involve ourselves with sustainable projects that add value to the entire ecosystem. Web3 ideas having short shelf life need rapid development and concurrent innovation to sustain in the competitive market."
  ),
  createSolution(
    "Decentralized Finance (DeFi)",
    "DeFi.svg",
    "DeFi-1.svg",
    "Decentralised finance has enabled borderless peer to peer transactions without the interference of third parties. This has shown unparalleled growth in adoption but is still constrained by surreal rewards, system maintenance, operational failures and infrastructural mishaps. DeFi poses threats to the existing financial institutions but doesn’t comply with regulations because of its open and distributed nature.",
    "We tend to make open source contributions of development tools and frameworks that empower creators and businesses. We aim to make decentralised solutions that improve security of individual’s assets and focus primarily on constructing foolproof mechanisms with concrete code logic alongside sustainable economic incentives."
  ),
  createSolution(
    "Tokenomics",
    "tokenomics.svg",
    "tokenomics-1.svg",
    "The decentralised nature of the Web3 space has allowed participants to create and sell meaningless tokens with little to no economic value. The mechanics behind the majority of these token economies are myopic in nature as they allow short term participants in the system to benefit at the expense of long-term users, ultimately making it a zero-sum game which is no different than gambling.",
    "We aim to replace the existing ‘ponzinomics’ framework of Web3 protocols with effective and robust token economic models, which not only create long term value for the users but also provide real utility to the token. We believe a successful token economy is reflective of a logical mechanism design which aligns user incentivization and value creation."
  ),
  createSolution(
    "NFTs",
    "NFT.svg",
    "NFT-1.svg",
    "The conventional concept of limiting NFTs to only digital artworks has confined the meaning of Non-fungible tokens. The main idea of NFTs was to create validity of digital assets in the form of unique metadata ownership.",
    "We believe in the generation and regeneration of valuable assets, which thrives past mainstream artwork nfts. Turning NFTs into highly liquid investments, enabling collateralized lending/borrowing, real world asset pegging and much more."
  ),
  createSolution(
    "Composability",
    "composibility.svg",
    "composibility-1.svg",
    "The nature of Web3 is subjected towards open-sourcing and creativity in the form of lego levels, and have caused traffic of users to rush here to only generate value out of it without any certain incentive for incremental innovation.",
    "We offer services that are entirely focused on transforming cryptography into a more adaptable and creative dynamic. Although protocol-level development in this domain can be challenging for novice users, our development team is committed to making these solutions more accessible throughout the network"
  ),
  createSolution(
    "L2 Solutions",
    "layer2-sol.svg",
    "layer2-sol-1.svg",
    "Pre-dominant DApps are constructed on layer 1 mechanisms, making them less scalable, higher latency and heavy on gas fee.",
    "The main layer will not be used for heavy computation resulting in high throughput, low gas fee and secure transactions. Security , decentralisation and scalability, all three of these won't get compromised with the layer 2 mechanism, by inheriting the layer 1 security and easing the network congestion."
  ),
  createSolution(
    "DSI",
    "DSI.svg",
    "DSI-1.svg",
    "The decentralised space is cluttered with fraudulent identities, which makes it vulnerable to identity thefts and getting hacked. People aren’t in control of their data and privacy.",
    "We never cut corners when it comes to decentralised architecture. We provide secure, portable, privacy-preserving digital identity solutions, enabling such benefits as KYC compliance and lower DeFi collateral requirements without the need for participants to disclose personal data allowing individuals and businesses to transact over open networks seamlessly and with the will of the user"
  ),
]

export const TECHNOLOGY = [
  createTechnology(
    "BLOCKCHAIN",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png"
  ),
  createTechnology(
    "WEB STACK",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png"
  ),
  createTechnology(
    "BQA",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png"
  ),
  createTechnology(
    "INFRA AND DEVOPS",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png",
    "techonology1.png"
  ),
]

export const JOBS = [
  createJob(
    "Software Developer",
    "karachi",
    "https://xord.solutions/work-with-us/"
  ),
  createJob(
    "Software Developer",
    "karachi",
    "https://xord.solutions/work-with-us/"
  ),
  createJob(
    "Software Developer",
    "karachi",
    "https://xord.solutions/work-with-us/"
  ),
  createJob(
    "Software Developer",
    "karachi",
    "https://xord.solutions/work-with-us/"
  ),
]

export const Expertise = [
  createExpertise("decentralized-exchange.png", "Digital Sovereign"),
  createExpertise("decentralized-exchange.png", "Digital Sovereign"),
  createExpertise("decentralized-exchange.png", "Digital Sovereign"),
  createExpertise("decentralized-exchange.png", "Digital Sovereign"),
  createExpertise("decentralized-exchange.png", "Digital Sovereign"),
  createExpertise("decentralized-exchange.png", "Digital Sovereign"),
  createExpertise("decentralized-exchange.png", "Digital Sovereign"),
  createExpertise("decentralized-exchange.png", "Digital Sovereign"),
  createExpertise("decentralized-exchange.png", "Digital Sovereign"),
  createExpertise("decentralized-exchange.png", "Digital Sovereign"),
]
export const Clients = [
  createClients("../images/blockchain-consultation-1.svg"),
  createClients("decentralized-exchange.png"),
  createClients("decentralized-exchange.png"),
  createClients("decentralized-exchange.png"),
  createClients("decentralized-exchange.png"),
  createClients("decentralized-exchange.png"),
  createClients("decentralized-exchange.png"),
  createClients("decentralized-exchange.png"),
  createClients("decentralized-exchange.png"),
]
