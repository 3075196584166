import React from "react"
import { Box, Text } from "@chakra-ui/react"

import Container from "../Container"
import { useAppContext } from "../../context"
import { useMediaQuery } from "@chakra-ui/react"

const Breadcrumb = ({
  image = "who-we-work-with-breadcrumb-banner.png",
  title,
  description,
}) => {
  const { images } = useAppContext()
  const [isLowerThan480] = useMediaQuery("(max-width: 480px)")

  return (
    <Container
      bg="primary"
      minHeight="calc(100vh - 80px)"
      backgroundImage={`url('${images[image]}')`}
      marginTop={isLowerThan480 ? "100px" : "0"}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        minHeight="calc(90vh - 100px)"
        paddingY="10vh"
        paddingX={[null, "10vw"]}
      >
        <Box />
        <Text
          textAlign="center"
          color="dark"
          fontSize={["5xl", null, "7xl"]}
          fontWeight="600"
        >
          {title}
        </Text>
        <Box bg="secondary" marginTop={5} width="10vw" height={2} />
        <Text color="darkgrey" textAlign="center" fontSize="xl">
          {description}
        </Text>
      </Box>
    </Container>
  )
}

export default Breadcrumb
