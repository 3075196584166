import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from "../components/breadcrumb"
import Solution from "../components/solution"
import TechnologyStack from "../components/techonologyStack"

const WhatWeDoPage = () => (
  <Layout>
    <Seo title="What we do" />
    <Breadcrumb
      description="Xord Solutions perceive blockchain as a source that constitutes infinite possibilities that exceed further from the mainstream use cases. We build blockchain products and give solutions along with the development of unique projects."
      title="What We Do"
      image="who-we-work-with-breadcrumb-banner.png"
    />
    <Solution />
    <TechnologyStack />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default WhatWeDoPage
